import React from 'react';
import '../index.css';

const Home = () => {
  return (
    <div className="video-container">
      <video autoPlay muted loop id="background-video" className="video-fluid">
        <source src="./video.MP4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="overlay-text">
        <p className='p'>BIENVENIDO AL SITIO OFICIAL DE</p>
        {/* Logo con efecto de luz */}
        <div className="logo-container">
          <img src="/logo.jpg" alt="Logo" className="logo" />
        </div>
      </div>
    </div>
  );
};

export default Home;




