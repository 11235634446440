import React from 'react';
import '../index.css';

const Dates = () => {
  return (
    <div className="fechas-container">
      <div className="cartel">
        <h1 className="lugar">CITYBAR MARTÍNEZ</h1>
        <p className="direccion">Av. Fondo de la Legua 2550, Martínez</p>
        <h2 className="fecha">17 DE NOVIEMBRE 2024</h2>
      </div>
    </div>
  );
};

export default Dates;
